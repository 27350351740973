<template>
  <v-stepper v-model="tab" :alt-labels="!this.$vuetify.breakpoint.smAndDown" flat class="elevation-0 twoFactor--stepper pa-0">
    <v-row class="ma-0 pa-0" justify="end" v-if="!this.redirect">
      <div >
        <language-menu class="justify-end" />
      </div>
    </v-row>
    <v-row class="ma-0 pa-0" v-if="!this.redirect">
      <span class="text-h6 font-weight-bold black--text">
        {{ $t('twofactor_stepper_title') }}
      </span>
    </v-row>
    <v-stepper-header elevation="0" class="elevation-0" v-if="tab > 0">
      <template v-for="(item, i) in tabs">
        <v-stepper-step
          :key="`${i}-tab`"
          :complete="tab > i + 1"
          :step="i + 1"
        >
          <span class="text-center">{{ $t(item.title) }}</span>
        </v-stepper-step>
        <v-divider :key="i" v-if="i + 1 < tabs.length" />
      </template>
    </v-stepper-header>
    <v-container v-if="tab == 0 && !this.reset">
      <Info>
        <template #buttons>
          <v-btn
            v-if="tab != maxTab"
            @click="close()"
          >
            {{ $t('btn_cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            v-if="tab == maxTab || tab == 0"
            @click="submit()"
          >
            {{ $t('btn_accept') }}
          </v-btn>
        </template>
      </Info>
    </v-container>
    <v-stepper-items>
      <v-stepper-content
        v-for="(item, i) in tabs"
        :key="`step-content-${i}`"
        :step="i + 1"
        class="pa-0"
      >
        <component
          :is="item.component"
          v-bind="{
            qrCode,
            code
          }"
          :ref="item.component.toLowerCase()"
          @verify="handleVerify"
          @close="close"
          @resetQR="getQR"
        >
          <template #before>
            <v-btn
              v-if="tab < 3 || tab === 3 && !codeVerify"
              @click="close()"
            >
              {{ $t('btn_cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn
              @click="change(false)"
              v-if="tab <= maxTab && tab > minTab"
              :disabled="(verifiedTab >= tab) || (reset && verifiedTab >= tab)"
            >
              {{ $t('btn_previous') }}
            </v-btn>
          </template>
          <template v-slot:button />
          <template #after>
            <v-btn
              color="primary"
              :disabled="(tab === 3 && !codeVerify) || (reset && tab === 1 && !codeVerify)"
              @click="change(true)"
              v-if="tab < maxTab && tab > 0"
            >
              {{ $t('btn_next') }}
            </v-btn>
            <v-btn
              color="primary"
              v-if="tab == maxTab || tab == 0"
              @click="submit()"
            >
              {{ $t('btn_accept') }}
            </v-btn>
          </template>
        </component>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapState } from 'vuex'
import LanguageMenu from '@/components/commons/LanguageMenu'
import Info from './partials/Info.vue'
import Download from './partials/Download.vue'
import ScanQR from './partials/ScanQR.vue'
import Confirm from './partials/Confirm.vue'
import Backup from './partials/Backup.vue'
import Completed from './partials/Completed.vue'
import Delete from './partials/Delete.vue'
import TwoFactorService from '@/services/twoFactor'

export default {
  components: {
    LanguageMenu,
    Info,
    Download,
    ScanQR,
    Confirm,
    Backup,
    Completed,
    Delete
  },
  data() {
    return {
      tabs: [
        {
          title: (this.reset ? 'twofactor_reset_label' : 'twofactor_download_label'),
          component: (this.reset ? 'Delete' : 'Download'),
        },
        {
          title: 'twofactor_scan_label',
          component: 'ScanQR',
        },
        {
          title: 'twofactor_verify_label',
          component: 'Confirm',
        },
        {
          title: 'twofactor_backup_label',
          component: 'Backup',
        },
        {
          title: 'twofactor_completed_label',
          component: 'Completed',
        },
      ],
      tab: 0,
      minTab: 1,
      maxTab: 5,
      verifiedTab: 0,
      codeVerify: false,
      qrCode: '',
      code: ''
    }
  },
  props: {
    reset: {
      type: Boolean,
      default: false
    },
    redirect: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('account', ['account']),
    broker() {
      return this.user?.broker
    },
    routeName() {
      const { redirect } = this.$route.query
      if (redirect) {
        return redirect
      }

      return this.account ? { name: 'Home' } : { name: 'AccountSelect' }
    }
  },
  async created() {
    this.reset ? this.tab = 1 : this.tab = 0
    this.getQR()
  },
  methods: {
    handleVerify(value) {
      if (value) this.verifiedTab = this.tab + 1
      this.codeVerify = value
    },
    async close() {
      if (this.reset && this.verifiedTab > 1 && this.verifiedTab <= 3) {
        const res = await this.$dialog.info({
          text: this.$t('twofactor_modal_warning_text'),
          title: this.$t('twofactor_modal_warning_title'),
          icon: false,
          color: 'primary',
          showClose: false,
          actions: {
            false: {
              text: this.$t('btn_no')
            },
            true: {
              text: this.$t('btn_yes'),
              color: 'primary'
            }
          }
        })
        if (!res) return
      }

      this.redirect
        ? this.$router.push(this.routeName)
        : this.$emit('verify', false)
    },
    submit() {
      this.tab == 0
        ? this.tab++
        : this.redirect
        ? this.$router.push(this.routeName)
        : this.$emit('verify', true)
    },
    change(val) {
      val ? this.tab++ : this.tab--
      if (this.tab === 3) {
        this.$refs.confirm[0].focus()
      }
      this.codeVerify = false
    },
    async getQR() {
      const { data } = await TwoFactorService.setUp()
      this.qrCode = data.qr_code
      this.code = data.secret_code
    }
  }
}
</script>

<style scoped>
.stepper__label {
  display: block;
  text-align: center;
}

.title {
  font-weight: 500;
  font-size: 1.286rem;
  line-height: 1.571rem;
}
</style>
<style>
.twoFactor--stepper .menu-item {
  padding: 0;
}

.twoFactor--stepper .row {
  margin: 0;
}

.twoFactor--stepper .v-stepper__content {
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
}

.twoFactor--stepper .v-stepper__items > .container {
  padding-left: 0px;
  padding-right: 0px;
}

.twoFactor--stepper .twoFactor--buttons {
  flex-wrap: wrap;
  gap: 8px;
}

.twoFactor--stepper hr {
  margin: 0;
  padding: 0;
}

.twoFactor--stepper hr.rounded {
  border-top: 2px solid;
  width: 400px;
}
</style>