<template>
  <div class="info-container mx-auto">
    <div class="d-flex flex-column justify-center align-center mb-12">
      <img :src="this.logo" :alt="this.altLogo" />
    </div>
    <v-row justify="center" align="center" class="my-12">
      <span class="header primary--text">{{ $t('twofactor_info_header') }}</span>
    </v-row>
    <v-row justify="center" align="center" class="my-12">
      <span class="header">{{ $t('twofactor_info_content_1') + ' ' + $t('twofactor_info_content_2') }}</span>
    </v-row>
    <v-row justify="center" align="center" class="my-12">
      <span class="header primary--text">{{ $t('twofactor_info_content_3') }}</span>
    </v-row>
    <v-row align-content="center">
      <v-col cols="12" class="d-flex justify-space-between justify-md-end twoFactor--buttons">
        <slot name="buttons"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      logo: '',
      altLogo: ''
    }
  },
  computed: {
    ...mapState('auth', ['user', 'tempUser']),
  },
  async created() {
    const user = this.user || this.tempUser
    this.logo = user.broker?.id ? `data:image/png;base64,${user.broker.img_desktop}` : require('@/assets/logo_av_securities.svg')
    this.altLogo = user.broker?.id ? `${user.broker.name} Logo` : 'AV Agil Logo'
  }
}
</script>

<style scoped>
.header {
  text-align: center;
  font: normal normal 500 16px/22px Lato;
  letter-spacing: 0px;
  color: #686F8D;
}
.info-container {
  max-width: 800px;
}
</style>