<template>
  <div>
    <v-container class="px-0" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('twofactor_scan_title') }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary"/>
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <span class="header ml-2"> {{ $t('twofactor_scan_content_1') }}</span>
        <img
          src="@/assets/002_google_au.png"
          alt="Google Authenticator Logo"
          width="218"
          height="40"
          class="mx-2"
        />
      </v-row>
      <v-row>
        <v-divider class="my-5"/>
      </v-row>
      <v-row class="px-0 px-md-6">
        <v-col cols="12" sm="12" md="6" class="text-left px-md-6">
          <v-row justify="start" align="center">
            <p class="header header--responsive pa-0">
              {{ $t('twofactor_scan_content_2') }}
              <img src="@/assets/005_plus.png" class="custom-icon" width="52" height="52"/>
              {{ $t('twofactor_scan_content_3') }}
            </p>
          </v-row>
          <v-row justify="start" align="center">
            <p class="header header--responsive pa-0">
              {{ $t('twofactor_scan_content_4') }}
              <img src="@/assets/006_cam.png" class="custom-icon" width="52" height="52"/>
              {{ $t('twofactor_scan_content_5') }}
            </p>
          </v-row>
          <v-img
            max-height="150"
            max-width="150"
            :src="qrCode"
            alt="Scan QR to authenticate"
            class="mx-auto"
          />
        </v-col>
        <v-col v-if="this.$vuetify.breakpoint.smAndDown" cols="12" sm="12">
          <v-divider :class="this.$vuetify.breakpoint.smAndDown? 'd-block' : 'd-none'" />
        </v-col>
        <v-divider vertical class="d-none d-md-block divider--fix" />
        <v-col cols="12" sm="12" md="6" class="text-left px-md-6">
          <v-row justify="start" align="center">
            <p class="header header--responsive pa-0">
              {{ $t('twofactor_scan_content_13') }}
            </p>
          </v-row>
          <v-row justify="start" align="center">
            <p class="header header--responsive pa-0">
              {{ $t('twofactor_scan_content_6') }}
              <img src="@/assets/005_plus.png" class="custom-icon" width="52" height="52"/>
              {{ $t('twofactor_scan_content_7') }}
            </p>
          </v-row>
          <v-row justify="start" align="center">
            <p class="header header--responsive pa-0">
              {{ $t('twofactor_scan_content_8') }}
              <img src="@/assets/007_teclado.png" class="custom-icon" width="52" height="52"/>
              {{ $t('twofactor_scan_content_9') }}
            </p>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12" lg="11">
          <v-row align="center" class="input-group mt-6 mt-md-0">
            <span class="header primary--text">{{ $t('twofactor_scan_content_10') }}</span>
            <input type="text" class="input mx-0 ml-sm-2" value="AV Agil" readonly/>
          </v-row>
          <v-row align="center" class="my-4 input-group">
            <span class="header primary--text">{{ $t('twofactor_scan_content_11') }}</span>
            <input type="text" class="input mx-0 ml-sm-2" :value="code" readonly/>
          </v-row>
          <v-row justify="start" align="center">
            <span class="header header--responsive">{{ $t('twofactor_scan_content_12') }}</span>
          </v-row>
            </v-col>
            <v-col cols="12" xs="0" sm="0" lg="1" class="custom-padding"/>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="ma-0 pa-0" />
    <v-row align-content="center">
      <v-col cols="12" class="d-flex justify-space-between justify-md-end twoFactor--buttons">
        <slot name="before"></slot>
        <slot name="after"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props:{
    qrCode:{
      type: String,
      required: true
    },
    code:{
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.header {
  text-align: center;
  font: normal normal 500 16px/22px Lato;
  letter-spacing: 0px;
  color: #686F8D;
}

.custom-icon {
  vertical-align: -20px;
}

.header--responsive {
  margin: auto;
}

.divider--fix {
  margin: 0 -1px;
}

.input {
  width: 260px;
  height: 32px;
  background: #F0F0F0 0% 0% no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  padding: 10px;
  outline: 0;
  border-bottom: solid 1px #949494;
  color: #666666;
}

.input-group {
  justify-content: end;
}

@media only screen and (max-width: 450px) { 
  .input-group {
    justify-content: start;
  }

  .input-group > span.primary--text {
    width: 100%;
    text-align: left;
  }

  .header--responsive {
    margin: 0;
    text-align: left;
  }
}

@media only screen and (min-width: 451px) and (max-width: 960px) { 
  .input-group {
    justify-content: center;
  }

  .input-group > span.primary--text {
    width: 100%;
    text-align: center;
  }
}
</style>
