import BaseService from './base'
import axios from '@/plugins/axios'

export default class QuestionUserService extends BaseService {
  static resource() {
    return 'api/v1/question_users'
  }

  static async getUserAnswers(questions){
    try {
      await axios.get('/sanctum/csrf-cookie')
      await axios.post(`${this.baseURL()}/api/v1/verify-questions-users`, {
        questions:questions
      })
    } catch (e) {
      throw e.response.data.message
    }
  }

  static async getQuestionsEmptys(questions){
    try {
      await axios.get('/sanctum/csrf-cookie')
      const {data} = await axios.post(`${this.baseURL()}/api/v1/question-not-in-user`,{
        questions: questions
      })
      return data
    } catch (e) {
      throw e.response.data.message
    }
  }
}
