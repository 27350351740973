<template>
  <div>
    <v-container class="px-0" fluid>
      <v-row justify="center" align="center" v-if="!this.twoFactor">
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t(this.title) }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary" />
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="header text-left">
            {{ $t('twofactor_reset_content') }}
          </p>
        </v-col>
      </v-row>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form @submit.prevent="verify">
          <v-row
            class="px-0 px-md-5"
            v-for="(question, i) in userQuestions"
            :key="i"
          >
            <v-col cols="12" md="12">
              <label for="">{{ $t(question.question) }}</label>
              <ValidationProvider
                :name="$t('login_security_answer')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  :label="$t('login_security_answer')"
                  :placeholder="$t('login_security_answer_placeholder')"
                  v-model="question.answer"
                  :error-messages="errors[0]"
                  autocapitalize="none"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :autofocus="i == 0"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-divider class="ma-0 pa-0" />
          <v-row align-content="center">
            <v-col
              cols="12"
              class="d-flex justify-space-between justify-md-end twoFactor--buttons"
            >
              <slot name="before"></slot>
              <slot name="button">
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="loading"
                  :disabled="invalid || done"
                  >{{ $t('btn_reset') }}</v-btn
                >
              </slot>
              <slot name="after"></slot>
            </v-col>
          </v-row>
        </form>
      </ValidationObserver>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TwoFactorService from '@/services/twoFactor'
import QuestionUserService from '@/services/questionUser'
import UserService from '@/services/user'
import SettingsService from '@/services/setting'

export default {
  data() {
    return {
      loading: false,
      code: '',
      show: false,
      quantityQuestions: null,
      userQuestions: [],
      questions: [],
      questionSelectedQuantity: null,
      questionSelecteds: [],
      done: false,
      userDelete: {}
    }
  },
  props: {
    twoFactor: Boolean,
    title: {
      type: String,
      default: 'twofactor_reset_label'
    }
  },
  computed: {
    ...mapState('auth', ['user', 'tempUser']),
    ...mapState('account', ['accounts']),

    items() {
      return this.questions.map(item => {
        return {
          ...item,
          disabled: this.userQuestions.findIndex(el => el.id === item.id) > -1
        }
      })
    }
  },
  async created() {
    this.loading = true
    this.invalid = true
    this.$emit('verify', false)
    try {
      this.userDelete = this.user || this.tempUser
      await this.getSettings()
      await this.verifyQuestionsExist()
      await this.populateUserQuestions()
    } catch (e) {
      if (e.response.status !== 401) this.$dialog.notify.error(e)
    } finally {
      this.loading = false
      this.invalid = false
    }
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser', 'setTempUser']),
    async verify() {
      this.loading = true
      try {
        await QuestionUserService.getUserAnswers(this.userQuestions)
        await TwoFactorService.delete()
        this.$emit('verify', true)
        await this.$dialog.warning({
          text: this.$t('twofactor_modal_success_text'),
          title: this.$t('twofactor_reset_success'),
          icon: false,
          color: 'primary',
          showClose: false,
          actions: {
            true: {
              text: this.$t('btn_accept'),
              color: 'primary'
            }
          }
        })
        if (this.user) {
          this.user.twofactor_habilitated = false
          this.setCurrentUser(this.user)
        }
        if (this.tempUser) {
          this.tempUser.twofactor_habilitated = false
          this.setTempUser(this.tempUser)
        }
        this.done = true
        this.$emit('resetQR')
      } catch (e) {
        this.done = false
        this.$dialog.notify.error(this.$t('twofactor_reset_error'))
      } finally {
        this.loading = false
      }
    },
    async verifyQuestionsExist() {
      const data = await UserService.find(this.userDelete.id, {
        include: 'questions'
      })
      this.questionSelectedQuantity = data.questions.length

      this.questions = data.questions
      for (let i = 0; i < this.questions.length; i++) {
        let indiceAleatorio = Math.floor(Math.random() * (i + 1))
        let temporal = this.questions[i]
        this.questions[i] = this.questions[indiceAleatorio]
        this.questions[indiceAleatorio] = temporal
      }
    },
    async getSettings() {
      const { data } = await SettingsService.fetch({
        'filter[key]': 'quantity_questions'
      })
      this.quantityQuestions = data.data[0].value
    },
    populateUserQuestions() {
      let quantity =
        this.questionSelectedQuantity >= this.quantityQuestions
          ? this.quantityQuestions
          : this.questionSelectedQuantity

      for (let i = 0; i < quantity; i++) {
        this.userQuestions.push({
          question_id: this.questions[i].id,
          question: this.questions[i].question,
          answer: '',
          user_id: this.userDelete.id
        })
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.input-width {
  max-width: 500px;
}

.header {
  text-align: center;
  font: normal normal 500 16px/22px Lato;
  letter-spacing: 0px;
  color: #686f8d;
}
</style>
