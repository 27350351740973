<template>
  <div>
    <v-container class="scan-qr-container" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('twofactor_backup_title') }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary"/>
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="pa-3 px-md-12">
        <p class="header text-left" v-html="$t('twofactor_backup_content_1')"></p>
      </v-row>
      <v-row justify="center" class="pa-3 text-center mb-4" align="center">
        <p class="ma-0 header primary--text">
          {{ this.code }}
        </p>
        <img
          height="38"
          width="38"
          src="@/assets/download.svg"
          alt="Click to download secret code"
          @click="download"
					class="download--button mx-2"
          v-if="code"
        >
      </v-row>
      <v-row justify="center" class="pa-3 px-md-12 mb-10">
        <p class="header text-left">
          {{ $t('twofactor_backup_content_2') }}
        </p>
      </v-row>
    </v-container>
    <v-divider class="ma-0 pa-0" />
    <v-row align-content="center">
      <v-col cols="12" class="d-flex justify-space-between justify-md-end twoFactor--buttons">
        <slot name="before"></slot>
        <slot name="after"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
	props:{
    code:{
      type: String,
      required: true
    }
  },
  methods:{
    download() {
			const blob = new Blob([ this.code ], { "type" : "text/plain" });
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = 'AV Agil 2FA Backup.txt'
			link.click()
    }
  }
}
</script>

<style scoped>
.header {
  text-align: center;
  font: normal normal 500 16px/22px Lato;
  letter-spacing: 0px;
  color: #686F8D;
}

.download--button {
	cursor: pointer;
}
</style>
