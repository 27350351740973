<template>
  <div class="confirm--twoFactor">
    <v-container class="px-0" fluid>
      <v-row
        justify="center"
        align="center"
        v-if="!this.twoFactor"
      >
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('twofactor_verify_title') }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary"/>
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="pa-3 px-md-12">
        <p class="header text-left">
          <span v-html="$t('twofactor_verify_content_1')"></span>
          <br />
          <span v-html="$t('twofactor_verify_content_2')"></span>
        </p>
      </v-row>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form @submit.prevent="verify">
          <v-row
            align-content="center"
            justify="center"
            class="pa-3 pt-2 mt-2 input-width mx-auto"
            :class="twoFactor? 'mb-0' : 'mb-16'"
          >
            <ValidationProvider
              :name="$t('twofactor_verify_form_label')"
              rules="digits:6|required"
              v-slot="{ errors, failedRules }"
            >
              <label class="header primary--text">{{
                $t('twofactor_verify_form_label')
              }}</label>
              <v-otp-input
                length="6"
                class="input-width"
                :label="$t('twofactor_verify_form_label')"
                type="number"
                v-model="code"
                ref="otpInput"
                @finish="verify"
                :error-messages="failedRules['digits']"
                autofocus
              ></v-otp-input>
              <span v-if="failedRules['digits']" class="red--text text--lighten-1">
                {{ errors[0] }}
              </span>
          </ValidationProvider>
          </v-row>
          <v-divider v-if="!twoFactor" class="ma-0 pa-0" />
          <v-row align-content="center">
            <v-col cols="12" class="d-flex twoFactor--buttons"
              :class="twoFactor? 'justify-center' : 'justify-space-between justify-md-end' ">
              <slot name="before"></slot>
              <slot name="button">
                <v-btn
                  color="primary"
                  @click="verify"
                  :disabled="invalid || done"
                  :loading="loading"
                >
                  {{ $t('btn_verify') }}
                </v-btn>
              </slot>
              <slot name="after"></slot>
            </v-col>
          </v-row>
          <v-row justify="end" class="px-0 px-md-12 mt-12" v-if="twoFactor">
            <a
              class="header primary--text text-right text-decoration-none"
              @click="reset"
            >
              {{ $t('twofactor_reset_link') }}
            </a>
          </v-row>
        </form>
      </ValidationObserver>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TwoFactorService from '@/services/twoFactor'

export default {
  data() {
    return {
      loading: false,
      code: '',
      done: false
    }
  },
  props: {
    twoFactor: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState('auth', ['user', 'tempUser']),
  },
  mounted() {
    this.focus()
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser', 'setTempUser']),
    async verify() {
      this.loading = true
      try {
        await TwoFactorService.verify({ code: this.code })
        this.$emit('verify', true)
        this.$dialog.notify.success(this.$t('twofactor_verify_success'))
        if(this.user) {
          this.user.twofactor_habilitated = true
          this.setCurrentUser(this.user)
        }
        if(this.tempUser) {
          this.tempUser.twofactor_habilitated = true
          this.setTempUser(this.tempUser)
        }
        this.done = true
      } catch (e) {
        this.done = false
        this.$dialog.notify.error(this.$t('twofactor_verify_error'))
      } finally {
        this.loading = false
      }
    },
    async reset() {
      this.$emit('reset')
    },
    focus(){
      this.$nextTick( () => { this.$refs.otpInput.focus() } )
    }
  }
}
</script>

<style scoped>
.confirm--twoFactor .row {
  margin: 0;
}

.header {
  text-align: center;
  font: normal normal 500 16px/22px Lato;
  letter-spacing: 0px;
  color: #686f8d;
}

.input-width {
  max-width: 336px;
}
</style>
