import axios from '@/plugins/axios'
import BaseService from './base'

export default class TwoFactorService extends BaseService {
  static resource() {
    return 'api/v1/twofactor'
  }
  static async setUp() {
    try {
      const { data } = await axios.get(`${this.baseURL()}/${this.resource()}/setup`)
      return data
    } catch (e) {
      throw e.response.data.message
    }
  }

  static async verify(payload) {
    try {
      const { data } = await axios.post(`${this.baseURL()}/${this.resource()}/verify`, payload)
      return data
    } catch (e) {
      throw e.response.data.message
    }
  }

  static async delete() {
    try {
      const { data } = await axios.post(`${this.baseURL()}/${this.resource()}/delete`)
      return data
    } catch (e) {
      throw e.response.data.message
    }
  }
}
